var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _vm.value
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.closePersonalInfoPanel,
                expression: "closePersonalInfoPanel",
              },
            ],
            staticClass: "personal-info",
          },
          [
            _c(
              "div",
              { staticClass: "personal-info-head" },
              [
                _c("en-user-logo", {
                  staticStyle: {
                    width: "60px",
                    height: "60px",
                    "-webkit-border-radius": "100%",
                    "-moz-border-radius": "100%",
                    "border-radius": "100%",
                    "line-height": "60px",
                  },
                  attrs: {
                    "user-name": _vm.personInfo.name,
                    "image-url": _vm.personInfo.userLogo,
                  },
                }),
                _c("div", { staticClass: "personal-info-basic" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.personInfo.name) + " "),
                  ]),
                  _c("div", { staticClass: "dept" }, [
                    _vm._v(" " + _vm._s(_vm.personInfo.postName) + " "),
                  ]),
                ]),
                _c("en-icon", {
                  staticClass: "go-gongsiquan",
                  attrs: { name: "gongsiquan", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.viewGsq.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "personal-info-body" }, [
              _c("div", { staticClass: "personal-info-body-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 公司 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.personInfo.tenantName) + " "),
                ]),
              ]),
              _c("div", { staticClass: "personal-info-body-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 部门 ")]),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _vm._v(" " + _vm._s(_vm.personInfo.deptName) + " "),
                    _vm.personInfo.userDeptPostData &&
                    _vm.personInfo.userDeptPostData.length
                      ? _c("en-icon", {
                          staticClass: "dept-icon",
                          attrs: { name: "jiangang-gongsiquan", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.showAllDept.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.showAllDeptInfo
                ? _c(
                    "div",
                    { staticClass: "personal-info-dept" },
                    _vm._l(
                      _vm.personInfo.userDeptPostData,
                      function (deptItem) {
                        return _c(
                          "div",
                          {
                            key: deptItem.id,
                            staticClass: "personal-info-dept-item",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "personal-info-dept-icon" },
                              [
                                _c("en-icon", {
                                  attrs: {
                                    name: "bianjichengyuan",
                                    size: "24",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "personal-info-dept-basic" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "personal-info-dept-basic-item",
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            deptItem.reTypeId == "001"
                                              ? "借调"
                                              : "兼岗"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        " " + _vm._s(deptItem.postText) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "personal-info-dept-basic-item",
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        " " + _vm._s(deptItem.deptText) + " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        " " + _vm._s(deptItem.leaderText) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _c("div", { staticClass: "personal-info-body-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 邮箱 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.personInfo.email) + " "),
                ]),
              ]),
              _c("div", { staticClass: "personal-info-body-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v(" 手机 ")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.personInfo.mobileNo) + " "),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "personal-info-sendMsg" },
              [
                _c(
                  "en-button",
                  {
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.sendMessage.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" 发消息 ")]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
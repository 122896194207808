<!--
 * @Description:
 * @Author: wuqi
 * @Date: 2020-12-01 10:11
 * @LastEditTime: 2021-08-19 16:29:49
 -->
<template>
  <el-popover
    v-model="visible"
    class="pop-cls"
    placement="bottom"
    width="378"
    trigger="click"
  >
   <personal-info v-model="visible" :userId="userId"></personal-info>
    <div slot="reference">
      <slot>
        <en-user-logo
          :user-name="userName"
          :image-url="userLogoUrl"
          :title="userName"
          :size="logoSize"
        ></en-user-logo>
        </slot>
    </div>
  </el-popover>
</template>
<script>
// import userPost from "@/views/companyCircle/user-post";
import { getFileUrl } from "@/tools/getFileUrl";
import personalInfo from "./personalInfo";

export default {
  name: "UserLogoInfo",
  components: { personalInfo },
  props: {
    logoSize: {
      type: Number,
      default: 40
    },
    // 是否显示跳转到公司群图标
    isShowCircle: { type: Boolean },
    // 当前查看用户id
    userId: { type: String, required: true },
    userName: {
      type: String,
      required: true,
      default: ""
    },
    userLogo: {
      type: String
    }
  },

  data() {
    return {
      showPersonalVisible: true,
      visible: false, // 弹窗是否显示
      isShowPost: false, // 是否显示兼岗信息
      postList: [
        {
          id: "1",
          f1: "兼岗",
          f2: "销售部",
          f3: "测试员",
          f4: "蔡工"
        }, {
          id: "2",
          f1: "兼岗",
          f2: "销售部",
          f3: "测试员",
          f4: "蔡工"
        }
      ]
    };
  },
  computed: {
    userLogoUrl() {
      return this.getFileUrlHandler(this.userLogo, "000", this.userName);
    }
  },
  methods: {
    getFileUrlHandler(url, type, name) {
      return getFileUrl(url, type, name);
    },
    // 发送消息按钮方法（跳转到沟通模块）
    sendMessage() {
      console.log("发送消息");
    },
    // 显示兼岗方法
    showPost() {
      this.isShowPost = !this.isShowPost;
    },
    // 跳转到当前id的公司圈页面
    toCircle() {
      this.visible = false;
      this.$router.push({ path: "/", query: { id: this.userId } });
    }
  }
};
</script>
<style >
.el-popover {
  min-width: 100px;
  padding: 0;
}
</style>
<style lang='scss' scoped>
.user-wrap {
  .user-row {
    padding: 20px;
    .user-icon {
      width: 60px;
      height: 60px;
      display: inline-block;
      vertical-align: middle;
    }
    .user-name {
      width: 220px;
      margin-left: 12px;
      display: inline-block;
      vertical-align: middle;

      .inner-name {
        font-size: 16px;
        color: #333333;
      }
      .inner-post {
        font-size: 12px;
        color: #636c78;
      }
    }
    .user-circle {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .user-post2 {
    padding-left: 20px;
    padding-right: 20px;
    .user-post2-name {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: #636c78;
      .post2-label {
        font-size: 12px;
        color: #333333;
      }
      .post2-name {
        font-size: 12px;
        color: #636c78;
        padding-left: 15px;
      }
    }
    .user-post2-icon {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px;
      cursor: pointer;
      .en-icon {
        color: #3e90fe;
      }
    }

    div {
      display: inline-block;
    }
  }
  .user-post {
    background-color: #f5f7fa;
    padding: 10px 14px 10px 14px;
  }
  .user-company,
  .user-email,
  .user-mobile {
    padding-left: 20px;
    padding-right: 20px;
    height: 25px;
    line-height: 25px;
    display: flex;
    .company-label {
      font-size: 12px;
      color: #333333;
    }
    .company-name {
      font-size: 12px;
      color: #636c78;
      padding-left: 15px;
    }
  }
  .user-foot {
    padding: 0 0 10px 0;
  }
  .user-send {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}
</style>

<template>
    <transition name="el-zoom-in-center">
      <div
          v-if="value"
          v-click-outside="closePersonalInfoPanel"
          class="personal-info"
        >
          <div class="personal-info-head">
            <en-user-logo
              :user-name="personInfo.name"
              :image-url="personInfo.userLogo"
              style="width: 60px;height: 60px;-webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;line-height: 60px;"
            >
            </en-user-logo>
            <div class="personal-info-basic">
              <div class="name">
                {{ personInfo.name }}
              </div>
              <div class="dept">
                {{personInfo.postName}}
              </div>
            </div>
            <en-icon name="gongsiquan" class='go-gongsiquan' size="small" @click.native.stop="viewGsq"></en-icon>
          </div>
          <div class="personal-info-body">
            <div class="personal-info-body-item">
              <div class="label">
                公司
              </div>
              <div class="value">
                {{personInfo.tenantName}}
              </div>
            </div>
            <div class="personal-info-body-item">
              <div class="label">
                部门
              </div>
              <div class="value">
                {{personInfo.deptName}}
                <en-icon
                  v-if="personInfo.userDeptPostData&&personInfo.userDeptPostData.length"
                  name="jiangang-gongsiquan"
                  size="small"
                  class="dept-icon"
                  @click.stop.native="showAllDept"
                >
                </en-icon>
              </div>
            </div>
            <div v-if="showAllDeptInfo" class="personal-info-dept">
              <div class="personal-info-dept-item" v-for="deptItem in personInfo.userDeptPostData" :key="deptItem.id">
                <div class="personal-info-dept-icon">
                  <en-icon name="bianjichengyuan" size="24" ></en-icon>
                </div>
                <div class="personal-info-dept-basic">
                  <div class="personal-info-dept-basic-item" >
                    <div class="label">
                      {{deptItem.reTypeId == "001" ? '借调' : '兼岗'}}
                    </div>
                    <div class="label">
                      {{deptItem.postText}}
                    </div>
                  </div>
                  <div class="personal-info-dept-basic-item" >
                    <div class="label">
                      {{deptItem.deptText}}
                    </div>
                    <div class="label">
                      {{deptItem.leaderText}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="personal-info-body-item">
              <div class="label">
                邮箱
              </div>
              <div class="value">
                {{personInfo.email}}
              </div>
            </div>
            <div class="personal-info-body-item">
              <div class="label">
                手机
              </div>
              <div class="value">
                {{personInfo.mobileNo}}
              </div>
            </div>
          </div>
          <div class="personal-info-sendMsg">
            <en-button type="primary" @click.native.stop="sendMessage">
              发消息
            </en-button>
          </div>
        </div>
    </transition>

</template>

<script>
import { mapState } from "vuex";
import { team } from "@/api/chat";
import Utils from "@/tools/chat";

export default {
  name: "PersonalInfo",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ""
    }
  },
  data() {
    return { showAllDeptInfo: false, personInfo: {} };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getPersonalInfo(this.userId);
      }
    }
  },
  computed: {
    session() {
      return this.sessionMap[this.currSessionId] || {};
    },
    ...mapState("chat", ["sessionMap", "currSessionId"]),
    isSelf() {
      return this.$store.state.userInfo.userId === this.userId;
    }
  },
  methods: {
    // 查看公司圈
    viewGsq() {
      console.log(this.$route);
      const routeUrl = this.$router.resolve({
        path: "/company-circle/home",
        query: {
          userId: this.userId
        }
      });
      this.showAllDeptInfo = false;
      this.$emit("input", false);
      window.open(routeUrl.href, "_blank");
    },
    // 发送消息
    async sendMessage() {
      const userlist = [this.personInfo];
      console.log(userlist);
      if (userlist.length === 1) { // 列表存在单聊 直接选中
        const imUserId = userlist[0].imUserId;
        const session = this.$store.state.chat.sessionlist.find((item) => item.to === imUserId);
        if (session) {
          this.$store.dispatch("chat/setCurrSession", session.id);
          this.closePersonalInfoPanel(session, "sendMessage");
          return;
        }
      }
      // 先同步资料
      const accounts = userlist.map((item) => item.imUserId) || [];
      this.$store.dispatch("chat/searchUsers", { accounts });

      const res = await team.createTeam(userlist);
      const obj = {};
      obj.sessionId = Utils.getSessionId(this.$store.state.chat.userId, res.sessionId);
      obj.scene = userlist.length > 1 ? "team" : "p2p";
      this.$store.dispatch("chat/insertLocalSession", obj);
      this.closePersonalInfoPanel({ id: `${obj.scene}-${obj.sessionId}`, unread: 0 }, "sendMessage");
    },
    async  getPersonalInfo(ID) {
      this.personInfo = await team.queryUserInfo({ id: ID });
    },
    /**
     * @description 关闭个人信息面板
     */
    closePersonalInfoPanel(session, type) {
      this.showAllDeptInfo = false;
      console.log(this.$route, "this.$router");
      if (type === "sendMessage") {
        this.$router.push({
          path: "/chat",
          query: { id: session.id, unread: session.unread }
        });
      }
      this.$emit("input", false);
    },
    /**
     * @description 展示个人兼岗信息
     */
    showAllDept() {
      this.showAllDeptInfo = !this.showAllDeptInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
  .personal-info {
    width: 380px;
    border: 1px solid #E8ECF2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 0 20px 0;
    background-color: #ffffff;
    z-index: 3;

    .personal-info-head {
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding: 0 20px 20px 20px;
      .go-gongsiquan{
        cursor: pointer;
      }
      .personal-info-basic {
        margin-left: 16px;

        .name {
          margin-top: 13px;
          color: #333333;
          font-size: 14px;
          line-height: 14px;
        }

        .dept {
          font-size: 12px;
          color: #636C78;
          line-height: 12px;
          margin-top: 8px;

        }
      }

      .en-icon {
        position: absolute;
        right: 20px;
        top: 21px;
      }
    }

    .personal-info-body {
      .personal-info-body-item {
        font-size: 12px;
        color: #333333;
        line-height: 28px;
        display: flex;
        justify-content: flex-start;
        padding: 0 20px;

        .label {
          margin-right: 15px;
        }

        .value {
          color: #636C78;
          position: relative;

          .dept-icon {
            color: #3e90fe;
            cursor: pointer;
            position: relative;
            top: 3px;
            left: 5px;
          }
        }
      }

      .personal-info-dept {
        background-color: #F5F7FA;
        padding: 20px 20px 20px;

        .personal-info-dept-item {
          background-color: #ffffff;
          padding: 20px 20px 20px;
          border: 1px solid #E8ECF2;
          border-radius: 4px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          align-items: center;
          .personal-info-dept-icon {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: #3e90fe;
            margin-right: 12px;
            display: flex;
            /* align-content: center; */
            align-items: center;
            justify-content: center;
            .en-icon {
              color: #ffffff;
            }
          }

          .personal-info-dept-basic {
            width: calc(100% - 52px);
            display: flex;
            justify-content: space-between;

            .personal-info-dept-basic-item {
              .label {
                margin-bottom: 15px;
                margin-top: 2px;
                font-size: 12px;
                color: #636C78;

                &:nth-last-child(1) {
                  margin-bottom: 0;
                  margin-top: 0;
                }
              }
            }

          }

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .personal-info-sendMsg {
      text-align: center;
      margin-top: 12px;
    }
  }
</style>

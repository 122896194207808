var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "pop-cls",
      attrs: { placement: "bottom", width: "378", trigger: "click" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("personal-info", {
        attrs: { userId: _vm.userId },
        model: {
          value: _vm.visible,
          callback: function ($$v) {
            _vm.visible = $$v
          },
          expression: "visible",
        },
      }),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [
          _vm._t("default", function () {
            return [
              _c("en-user-logo", {
                attrs: {
                  "user-name": _vm.userName,
                  "image-url": _vm.userLogoUrl,
                  title: _vm.userName,
                  size: _vm.logoSize,
                },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }